<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="false"
      :show-delete="false"
      :show-save="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <audit-trail-side-menu v-if="options"
          v-model="options" />
      </div>
      <div class="column">
        <audit-trail-detail v-if="options && showEditor && entity"
          v-model="entity.newValue"
          :options="options" />
      </div>
    </div>
  </div>
</template>

<script>
import AuditTrailSideMenu from './AuditTrailSideMenu'
import AuditTrailRoutes from './route-types'
import AuditTrailValidation from './AuditTrailValidation'
import AppDetailHeader from '@/components/AppDetailHeader'
import EventBus from '@/components/EventBus'
import AuditTrailDetail from './AuditTrailDetail'
import { AppHeaderButtonTypes, EventHubTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'AuditTrailView',
  components: {
    AppDetailHeader,
    [AuditTrailSideMenu.name]: AuditTrailSideMenu,
    AuditTrailDetail
  },
  mixins: [AuditTrailValidation, StoreMixin],
  props: {
    isNew: Boolean,
    returnUrl: String,
    type: String
  },
  data() {
    return {
      entity: null,
      isConfirmModalActive: false,
      deleteAndSave: false,
      showEditor: true,
      options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        line: true,
        matchBrackets: true,
        showCursorWhenSelecting: true,
        lineSeparator: '\n',
        lineWrapping: false,
        mode: 'application/xml',
        theme: 'default'
      },
      toRoute: null
    }
  },
  computed: {},
  watch: {
    'options.theme': {
      handler: function(newVal, oldVal) {
        this.showEditor = false
        this.$nextTick(function() {
          this.showEditor = true
        })
      },
      deep: true
    }
  },
  beforeDestroy() {},
  async created() {
    this.getEntity()
    if (this.$userInfo.codemirrorTheme) {
      this.options.theme = this.$userInfo.codemirrorTheme
    }
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          this.isConfirmModalActive = true
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSnapshots(this.$route.params.auditTrailFieldId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.auditTrailFieldId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(isDelete) {
      const title = 'AuditTrail'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }
      this.snapshotDiff
      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))
        this.$router.push({
          name: AuditTrailRoutes.AuditTrailListView.name,
          filter: this.filter,
          query: {
            filter: this.encodedFilter
          }
        })
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
        console.log(this.snapshotDiff)
        this.isUnsavedModalActive = true
      } else {
        if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else {
          this.$router.push({
            name: AuditTrailRoutes.AuditTrailListView.name
          })
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.clearSnapshots(this.entity.messageId)
    next()
  }
}
</script>
