<template>
  <app-side-menu
    :min-width="18"
    :fixed-menu="false">
    <aside
      class="menu"
      slot="side-menu">
      <ul class="menu-list">
        <li>
          <div class="field">
            <label class="label">Theme</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="value.theme">
                  <option
                    v-for="(theme , index) in themes"
                    :value="theme"
                    :key="index">{{ theme }}</option>
                </select>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import AuditTrailRoutes from './route-types'
import { CodemirrorThemes } from '@/components/VueCodemirror'

export default {
  name: 'AuditTrailSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  props: {
    value: null,
    entityName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      themes: null,
      innerValue: null
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return AuditTrailRoutes
    }
  },
   watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    'innerValue.theme': function(newVal, oldVal) {
      this.$setCodemirrorTheme(newVal)
    }
  },
  created() {
    this.themes = CodemirrorThemes
    this.innerValue = this.value
  },
  methods: {
     toggleLineWrapping() {
      this.innerValue.lineWrapping = !this.innerValue.lineWrapping
    }
  }
}
</script>
